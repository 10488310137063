<template>
  <div>
    <staticResourceTabs
      :isValid="isValid"
      v-on="$listeners"
      :type="valueLine.target.type"
    >
      <template v-slot:tabsContent="data">
        <div class="mb-n9" v-if="data.item == 'static' && valueLine.target.type == 'static'">
          <lstStaticContent
            :localStoredStep="valueLine"
            v-on="$listeners"
            :result="result"
            :isValid="isValid"
            :hideDetails="false"
          ></lstStaticContent>
        </div>
        <div class="mb-n9" v-if="data.item == 'resource' && valueLine.target.type == 'resource'">
          <lstResourceContent
            :localStoredStep="valueLine"
            v-on="$listeners"
            :result="result"
            :isValid="isValid"
            :hideDetails="false"
          ></lstResourceContent>
        </div>
      </template>
    </staticResourceTabs>
    <div>
      <fieldsRows
        :templateContent="templateContent"
        :valueLine="valueLine"
        :isValid="isValid"
        v-on="$listeners"
      ></fieldsRows>
    </div>
  </div>
</template>
<script>
import staticResourceTabs from "@/components/legacy/staticResourceTabs.vue";
import lstStaticContent from "@/components/legacy/lstStaticContent.vue";
import lstResourceContent from "@/components/legacy/lstResourceContent.vue";
import fieldsRows from "@/commonComponents/fieldsRows.vue";

export default {
  data() {
    return {
      //attrSource: "t_testcases",
      //cachedParametersAttributes: []
    };
  },
  components: {
    staticResourceTabs,
    lstStaticContent,
    lstResourceContent,
    fieldsRows,
  },
  props: {
    result: {},
    field: String,
    isValid: Boolean,
    valueLine: {},
    value: {},
  },
  methods: {},
  computed: {
    templateContent() {
      let templateContent = {
        //TO DO
        fields: {
          testDirection: {
            inputFieldFormatter: "lstDirection",
          },
        },
        // stepType: this.stepType,
        // stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
};
</script>
<template>
  <div>
    <v-row>
      <v-col cols="2" class="">
        <associatedOption
          :fieldAttributes="{
            name: 'IP Version',
            associatedOption: localIpv,
            value: 'ipv4',
          }"
          type="select"
          :fieldAttrInput="{ class: 'required' }"
          v-model="ipVersion"
          :templateContent="result"
          :isValid="isValid"
          field="ip"
        ></associatedOption>
      </v-col>
      <v-col cols="10">
        <ip4ip6ValidatedInput
          field="host"
          :fieldAttributes="{ name: 'URL' }"
          v-model="host"
          :valueLine="this.localStoredStep.target"
          :result="result"
          :isValid="isValid"
          :required="this.localStoredStep.target.type == 'static'"
           v-on="$listeners"
        >
        </ip4ip6ValidatedInput>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import ip4ip6ValidatedInput from "@/components/dynamic/ip4ip6ValidatedInput.vue";

export default {
  props: ["localStoredStep", "result", "isValid"],
  data: function () {
    return {
      localIpv: [
        ["ipv4", "IPv4"],
        ["ipv6", "IPv6"],
      ],
      localProtocol: [
        ["tcp", "TCP"],
        ["udp", "UDP"],
      ],
    };
  },
  components: {
    associatedOption,
    ip4ip6ValidatedInput,
  },
  computed: {
    ipVersion: {
      get() {
        //console.log(this.valueLine);
        let ipVersion = "ipv4";
        if (typeof this.localStoredStep.target.ip != "undefined") {
          ipVersion = this.localStoredStep.target.ip;
        }
        return ipVersion;
      },
      set(selected) {
        console.log(selected);
        this.$set(this.localStoredStep.target, "ip", selected);
      },
    },
    transportProtocol: {
      get() {
        //console.log(this.valueLine);
        let tProtocol = "tcp";
        if (typeof this.localStoredStep.target.protocol != "undefined") {
          tProtocol = this.localStoredStep.target.protocol;
        }
        return tProtocol;
      },
      set(selected) {
        console.log(selected);
        this.$set(this.localStoredStep.target, "protocol", selected);
      },
    },
    host: {
      get() {
        let host = "";

        if (typeof this.localStoredStep.target.host != "undefined") {
          host = this.localStoredStep.target.host;
        }
        return host;
      },
      set(newVal) {
        this.$set(this.localStoredStep.target, "host", newVal);

        this.updateResponseKey();
      },
    },
    port: {
      get() {
        let port = "";
        if (typeof this.localStoredStep.target.port != "undefined") {
          port = this.localStoredStep.target.port;
        }
        return port;
      },
      set(newVal) {
        this.$set(this.localStoredStep.target, "port", newVal);
        this.updateResponseKey();
      },
    },
  },
  methods: {
    updateResponseKey() {
      this.$emit("stored-value-changed", this.localStoredStep);
    },
  },
};
</script>